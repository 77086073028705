import { useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import useSWR from 'swr';

import { IPost } from '../../../utils/api';

import { highlightImageHeight, highlightImagePadding } from './constants';
import HighlightItem from './highlight-item';

import styles from './top-area.module.scss';

export default function TopArea({ hideCarousel = false }: { hideCarousel?: boolean }) {
  const { data: highlights } = useSWR<IPost[]>('/api/posts/highlights/top_area');

  const {
    palette: {
      primary: { light, main },
    },
  } = useTheme();

  return (
    <aside className={styles.topArea}>
      {/* <Typography variant="inverted" className={styles.catchphrase}>
        <p>Aqui formamos através de treinamentos e eventos,</p>
        <p>mulheres fortes, corajosas, femininas e prósperas.</p>
      </Typography> */}

      {!hideCarousel && (
        <Carousel
          activeIndicatorIconButtonProps={{ style: { color: main } }}
          indicatorIconButtonProps={{ 
            style: {color: light} 
          }}
          indicatorContainerProps={{
            style: { 
              textAlign: "right",
              marginTop: "0px"
            } 
          }}
          height={highlightImageHeight + highlightImagePadding * 2}
          className={styles.Carousel}
        >
          {highlights?.map((p, index) => (
            <HighlightItem key={p.id} post={p} priority={index === 0} />
          ))}
        </Carousel>
      )}
    </aside>
  );
}
