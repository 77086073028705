import { Fragment, useCallback } from 'react';
import { Box } from '@mui/material';
import useSWRInfinite from 'swr/infinite';

import { IPost, Paginated } from '../../../utils/api';
import Advertisement from '../advertisement';

import PostSummary from './post-summary';

import styles from './posts.module.scss';

export default function Posts() {
  const { data: postPages } = useSWRInfinite<Paginated<IPost>>(
    useCallback((page) => `/api/posts?page=${page + 1}`, []),
  );

  return (
    

    <div className={styles.posts}>

      <Advertisement />

      <h4>Últimas notícias</h4>

      <Box className={styles.lista}>
      {postPages?.map((posts, page) => (
        <Fragment key={page}>
          {posts.items.map((p) => (
            <Fragment key={p.id}>
              <PostSummary key={p.id} id={p.id} img={p.coverImage} data={p.createdAt} titulo={p.title}>
                {p.summary}
              </PostSummary>
            </Fragment>
          ))}
        </Fragment>
      ))}
      </Box>
    </div>
  );
}
