import { Box } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { IPost } from '../../../utils/api';
import { useRealUrl } from '../../../utils/images';

import styles from './middle-highlights.module.scss';

type Props = PropsWithChildren<{ post: IPost }>;

export default function HighlightItem({ post }: Props) {
  const realUrl = useRealUrl(post.coverImage);

  return (
      <Box className={styles.cont}>
        {!realUrl ? (
          <span />
        ) : (
          <Link href={`/posts/${post.id}`}>
            <Box className={styles.contText}>
              <h3>{post.title.toLocaleLowerCase()}</h3>
            </Box>
            <Image src={realUrl} width={339} height={226} alt={post.title} className="img" />
          </Link>
        )}
      </Box>
  );
}
