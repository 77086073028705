import { useMediaQuery, useTheme, Box } from '@mui/material';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { IconCalendarEvent } from '@tabler/icons-react';

import { useRealUrl } from '../../../utils/images';
import CustomFormattedDate from '../../generic/custom-formatted-date';

import styles from './posts.module.scss';

interface Props {
  id: number;
  img?: string;
  data: string;
  titulo: string;
  children: string;
}

export default function PostSummary({ id, img, data, titulo, children }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const realUrl = useRealUrl(img);

  return (
    <article className={clsx(styles.newsCard, { [styles.noImage]: !img, [styles.mobile]: isMobile })}>
      {!realUrl ? (
        <span />
      ) : (
        <Link href={`/posts/${id}`}>
          <Image src={realUrl} width={339} height={226} alt={titulo} className="img" />
          <Box className={styles.content}>
            <p><IconCalendarEvent stroke={1.5} /> <CustomFormattedDate value={data} /></p>
            <h1>
              <Link href={`/posts/${id}`}>{titulo}</Link>
            </h1>
            <summary>
              <span>{children}</span>
            </summary>
          </Box>
        </Link>
      )}
      
    </article>
  );
}
