import useSWR from 'swr';
import { Swiper, SwiperSlide } from "swiper/react";

import { IPost } from '../../../utils/api';

import HighlightItem from './highlight-item';

import styles from './middle-highlights.module.scss';

// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { FreeMode } from "swiper";

export default function MiddleHighlights() {
  const { data: highlights } = useSWR<IPost[]>('/api/posts/highlights/middle_area');

  return (
    <div className={styles.list}>
      <Swiper
        breakpoints={{
          800: {
            slidesPerView: 3.3,
          },
          450: {
            slidesPerView: 1.5,
          },
          0: {
            slidesPerView: 1.2,
          },
        }}
        slidesPerView={3.3}
        spaceBetween={10}
        freeMode={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[FreeMode]}
        // modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {highlights?.map((p) => (
          <SwiperSlide className={styles.item} key={p.id}>
            <HighlightItem post={p} />
          </SwiperSlide>
        ))}
        
      </Swiper>
    </div>
  );
}
