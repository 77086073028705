import { ComponentProps, ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

interface Props extends Omit<ComponentProps<typeof FormattedDate>, 'value' | 'children'> {
  value: string | undefined;
  label?: ReactNode;
  children?: ReactNode;
}

export default function CustomFormattedDate({ value, label, children, ...props }: Props) {
  const inner = (
    <time dateTime={value} title={value}>
      {children ?? <FormattedDate value={value} {...props} />}
    </time>
  );

  if (!label) return inner;

  return (
    <span>
      {label} {inner}
    </span>
  );
}
