import { Typography, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import Image from 'next/image';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { IAdvertisement } from '../../../utils/api';
import { fixImageUrl } from '../../../utils/images';

import styles from './advertisement.module.scss';

export default function Advertisement() {
  const { data: ads } = useSWRImmutable<IAdvertisement[]>('/api/ads');
  const showLarge = useMediaQuery('(min-width: 900px)');
  const showMedium = useMediaQuery('(min-width: 660px)');

  const ad = useMemo(() => _.sample(ads), [ads]);

  const imgProps = useMemo(() => {
    if (!ad) return null;

    if (showLarge && ad.largeImage) return { src: fixImageUrl(ad.largeImage), width: 1152, height: 144 };
    if (showMedium && ad.mediumImage) return { src: fixImageUrl(ad.mediumImage), width: 720, height: 144 };
    if (ad.smallImage) return { src: fixImageUrl(ad.smallImage), width: 390, height: 390 };
  }, [ad, showLarge, showMedium]);

  const contents = (
    <Typography className={styles.publi} component="aside">
      {!imgProps ? 'Publicidade' : <Image alt={ad?.altText ?? 'Publicidade'} {...imgProps} />}
    </Typography>
  );

  return !ad?.uri ? (
    contents
  ) : (
    <a target="_blank" href={ad.uri} rel="noreferrer">
      {contents}
    </a>
  );
}
