import { Box, Button } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import { IPost } from '../../../utils/api';
import { fixImageUrl } from '../../../utils/images';

import { highlightImageHeight, highlightImagePadding } from './constants';


import styles from './top-area.module.scss';

interface Props {
  post: IPost;
  priority?: boolean;
}

export default function HighlightItem({ post: p, priority }: Props) {

  return (
    <Link href={`/posts/${p.id}`} passHref legacyBehavior>
      <Box component="a" className={styles.item} sx={{ padding: `${highlightImagePadding}px` }}>
        <Box className={styles.img} sx={{ height: highlightImageHeight }}>
          <Image
            src={fixImageUrl(p.topImage ?? '')}
            alt={p.title}
            sizes="90vw"
            fill
            style={{ objectFit: 'cover' }}
            priority={priority}
          />
        </Box>
        <figcaption>
          <p>{p.title.toLowerCase()}</p>
          <Button variant="outlined" className={styles.button}>
            Saiba mais
            <Box paddingLeft="10px" paddingTop="5px"><IconArrowNarrowRight stroke={1} /></Box>
          </Button>
        </figcaption>
      </Box>
    </Link>
  );
}
